.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-height: 700px) {
    row-gap:3px;    
  }
  @media screen and (min-height: 701px) {
    row-gap:8px;    
  }
  max-width: 500px;
  margin: 0 auto;
}

p {
  margin: 5px;
}

.container header {  
  display:flex;
  flex-direction: row;
  justify-content: space-around;  
  column-gap: 10px;
  margin: 10px;
  margin-top: 20px;
}

#game-description {
  padding: 20px;
  padding-top: 0px;
  text-align: center;
}

#game-description .emphasis {
  color: #405e3a;
  font-weight: bold;
  font-size: 20px;
}

.letter-rows {
  display: flex;
  flex-direction: column;
  margin: 3px;
  row-gap: 5px;
}

.letter-row-container {
  display: flex;
  flex-direction: column;  
  position: relative;
}

.letter-row-notice {
  position: absolute;
  @media screen and (max-height: 700px) {
    left: 58px;  
  }
  @media screen and (min-height: 701px) {
    left: 80px;  
  }
  bottom: -15px;
  z-index: 100;
  text-align: center;
  padding: 5px;
  background-color: white;
  opacity: 0.7;
  border-radius: 5px;
  cursor: pointer;
}

.letter-row {
  display: flex;
  flex-direction: row;  
  column-gap: 5px;
}

.letter {
  @media screen and (max-height: 700px) {
    font-size: 24px;    
    min-width: 28px;
    min-height: 28px;
  }
  @media screen and (min-height: 701px) {
    font-size: 30px;   
    min-width: 37px;
    min-height: 37px;
  }
  font-weight: bold;
  padding: 10px;
  
  text-align: center;
  background-color: #eee;
}

.letter-gray {
  background-color: #777;
  color: white;
}

.letter-green {
  background-color: #405e3a;
  color: white;
}

.letter-red {
  background-color: #c9322a;
  color: white;
}

.letter-yellow {
  background-color: #f9c563;
  color: white;
}

.letter-unknown {
  background-color: #bb9595;
  color: white;
}

.createGame {
  font-size: 20px;  
  font-weight: bold;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width:80%;  
  border-radius: 10px;
  border-color: black;
  background-color: #405e3a;
  padding: 20px;   
  padding-bottom: 30px;
  row-gap: 15px;
}


.newGameSymbolContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  column-gap: 10px;
  color: #aaa;
}

.newGameSymbol {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.newGameSymbol .row {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}

.newGameSymbol .row div {
  width: 10px;
  height: 10px;
  background-color: #ccc;
}

.newGameSymbol .firstrow div {
  width: 10px;
  height: 10px;
  background-color: #405e3a;
}

.arrow {
  font-size: 20px;
  margin-left: 10px;
}

.phone {
  font-size: 40px;
}

.previousGames {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  width:80%;
  border-radius: 10px;
  background-color: #f9c563;
  padding: 15px;
  row-gap: 10px;
}

.enterCode input {
  width: 60%;
  font-size: 30px;
}

#input {
  display: flex;
  flex-direction: row;
  column-gap: 10px;  
  height: 15%;
}

#input .selected {
  background-color: #bb9595;
}

#list {
  width: 95%;
  @media screen and (max-height: 700px) {
    height: 180px;    
  }
  @media screen and (min-height: 701px) and (max-height: 850px) {
    height: 250px;    
  }
  @media screen and (min-height: 851px) {
    height: 450px;    
  }
  overflow: scroll;
}

#list button {
  display: block;
  outline: none;
  height: 30px;
  border: 0px;
  background-color: #d4ce20;
  font-size: 20px;
  font-weight: bold;
  color: white;
  padding: 5px;
  padding-block: 5px;
  padding-inline: 5px;
  width: 60%;
  text-align: center;
  border-radius: 15px;
  margin: 3px auto;
}

.keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 25%;
}

.keyboardRow {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
}

.key {
  display: block;
  padding-top: 13px;
  padding: 7px;  
  min-height: 50px;  
  border: 0px;
  border-radius: 3px;
  background-color: #ccc;
  align-items: center;  
  color: #000;
  cursor: pointer;

  @media screen and (max-width: 373px) {
    /* small keyboard */        
    margin: 1px;
    min-width:30px;    
  }

  @media screen and (min-width: 374px) and (max-width: 403px) {
    /* mid keyboard */        
    margin: 2px;
    min-width:33px;    
  }

  @media screen and (min-width: 404px) and (max-width: 434px) {
    /* mid keyboard */        
    margin: 3px;
    min-width:34px;    
  }
  @media screen and (min-width: 434px) {
    /* mid keyboard */        
    margin: 3px;
    min-width:37px;    
  }
}

.keyboard-small button.key {
  padding-top: 13px;
  padding: 7px;
  margin: 1px;
  min-width:30px;    
}

.keyboard-mid button.key {
  padding-top: 13px;
  padding: 7px;
  margin: 2px;
  min-width:33px;    
}

.keyboard-large button.key {
  padding-top: 13px;
  padding: 7px;
  margin: 3px;
  min-width:34px;
}

.keyboard-max button.key {
  padding-top: 13px;
  padding: 7px;
  margin: 3px;
  min-width:37px;
}

.key-green {
  color: #fff;
  background-color: #405e3a;
}

.key-yellow {
  color: #fff;
  background-color: #f9c563;
}

.key-darkgray {
  color: #fff;
  background-color: #777;
}

.key:hover, .key:active {
  background-color: '#eee';
}

.keyText {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

.shareArea {
  display: flex;
  flex-direction: row;
  row-gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
}

.shareArea button {
  margin-right: 5px;
  margin-left: 5px;
  padding: 10px;
}

.container header.play-game {
  margin-top: 15px;
}

.lost-word {
  display: inline-block;
}

.lost-word div {
  display: inline-block;
  color: white;
  background-color: #c9322a;
  padding: 2px;
  width: 28px;
  text-align: center;
  margin-left: 2px;
  font-weight: bold;
  font-size: 25px;
}

.stats {
  font-size: 20px;
}

span.num {
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  background-color: #bb9595;
  color: white;
}

.stats .game-stats table {
  width: 200px;
}

.stats .game-stats .bar {
  width: 100px;
}

.stats .game-stats .bar div {
  height: 20px;
  background-color: #c9322a;
}

header.menu {
  font-size: 17px;
  justify-content: left;
}

#menuContainer {
  width: 100%;
  position: relative;
}

.menu button {
  font-size: 16px;
  outline: none;
  border: 0;
  background-color: transparent;
}

.menu span {
  display: inline;
}

#menu {  
  display: none;
  z-index: 10;
  background-color: #fff;
  opacity: 0.95;
  width: 300px;
}

#menu div {
  padding-bottom: 5px;
  padding-left: 13px;
}

#menu a {
  text-decoration: none;
}

#menu a, #menu a:visited, #menu a:hover, #menu a:active {
  color: inherit;
}

#menu input {
  width: 100px;
}

#gameList {
  display: flex;
  width: 80%;
  flex-direction: column;
}

.gameTitle {
  display: block;
  font-size: 20px;
  font-weight: bold;
}

.gameCode {
  display: block;
  font-size: 12px;
  font-weight: bold;
}

.gameDate {
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
}

.gameCard { 
  display: flex;
  flex-direction: row;
  padding: 20px;
  background-color: #405e3a;
  color: white;
  margin: 5px;
  border-radius: 10px;
}

.gameCard .leftSide {
  width: 50%;
}

.gameCard .leftSide button.details {
  color: black;
  font-weight: bold;
  border: 0;
  background-color: #f9c563;
  border-radius: 5px;
  padding: 5px;
}

.gameCard .leftSide button.continue {
  color: white;
  font-weight: bold;
  border: 0;
  background-color: #c9322a;
  border-radius: 5px;
  padding: 5px;
}

.gameCard .rightSide {
  width: 50%;
  white-space: pre-wrap;
}

.gameAverage {
  font-size: 30px;
  font-weight: bold;
}

.redButton {
  color: white;
  font-weight: bold;
  border: 0;
  background-color: #c9322a;
  border-radius: 5px;
  padding: 5px;
}

.greenButton {
  color: white;
  font-weight: bold;
  border: 0;
  background-color: #405e3a;
  border-radius: 5px;
  padding: 5px;
}

.yellowButton {
  color: black;
  font-weight: bold;
  border: 0;
  background-color: #f9c563;
  border-radius: 5px;
  padding: 5px;
}

.grayButton {
  color: black;
  font-weight: bold;
  border: 0;
  background-color: #c9c3b6;
  border-radius: 5px;
  padding: 5px;
}

#buttons {
  display: flex;
  flex-direction: row;
  column-gap: 30px;  
}

.gameState .username {
  font-size: 20px;
  font-weight: bold;  
}

.gameState .symbols {
  white-space: pre-wrap;
}

.historyButtons {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.liveGames {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 10px;
  justify-content: center;
}

.liveGames>.gameState:first-child {
  flex: 0 1 100%;
  padding-bottom: 20px;
  text-align: center;
}